import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import ShowMenuProvider from './contexts/ShowMenuProvider';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './components/home';
import Ready from './components/ready';
import Water from './components/water';
import AfterPayment from './components/after-payment';
import Issue from './components/issue';
import Nearest from './components/nearest';
import AddToHomescreen from './components/add-to-home-screen';

import AboutPage from './pages/AboutPage';
import InstructionPage from './pages/InstructionPage';
import RefundPage from './pages/RefundPage';
import PolicyPage from './pages/PolicyPage';
import OfferPage from './pages/OfferPage';
import ContactsPage from './pages/ContactsPage';


export default function App() {
    return (
        <Container fluid className="App">
            <BrowserRouter>
                <ShowMenuProvider>
                    <Header />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/ready/:id" component={Ready} />
                        <Route path="/water/:id" component={Water} />
                        <Route path="/after-payment/:id" component={AfterPayment} />
                        <Route path="/issue/:id" component={Issue} />
                        <Route path="/nearest/:id" component={Nearest} />
                        
                        <Route path="/about" component={AboutPage} />
                        <Route path="/instruction" component={InstructionPage} />
                        <Route path="/refund" component={RefundPage} />
                        <Route path="/policy" component={PolicyPage} />
                        <Route path="/offer" component={OfferPage} />
                        <Route path="/contacts" component={ContactsPage} />
                        
                        <Redirect to='/' />
                    </Switch>
                    <Route exact path='/' component={Footer} />
                    <Route path={['/ready', '/water', '/after-payment', '/issue', '/nearest']} component={Footer} />
                </ShowMenuProvider>
            </BrowserRouter>
            <AddToHomescreen />
        </Container>
    );
}
